/* eslint-disable import/prefer-default-export */
/* eslint-disable no-underscore-dangle */
import store from '../store';
import vueRouter from '../router';

export const router = {
  writeDelay: 250,
  _onPopState: null,
  read() {
    const { q, ...refinements } = vueRouter.currentRoute.query || {};

    const filteredRefinements = Object.keys(refinements).reduce((carry, refinement) => {
      /* eslint-disable no-param-reassign */
      carry[refinement] = refinements[refinement].split('~');
      /* eslint-enable no-param-reassign */
      return carry;
    }, {});

    const index = store.getters['directory/index'];

    return {
      [index]: {
        query: q ?? undefined,
        refinementList: filteredRefinements,
      },
    };
  },
  write(routeState) {
    const url = this.createURL(routeState);
    vueRouter.push(url).catch((err) => err);
  },
  createURL(routeState) {
    if (routeState === undefined) {
      return vueRouter.resolve('/');
    }

    // Gets everything after the underscore for the index name
    const index = Object.keys(routeState)[0];
    const directory = index.split('_')[1];

    return vueRouter.resolve({
      query: routeState[index],
      params: {
        directory,
      },
    }).href;
  },
  onUpdate(cb) {
    this._onPopState = ({ state }) => {
      const { key, ...routeState } = state ?? {};

      if (Object.keys(routeState).length === 0) {
        cb(this.read());
      } else {
        cb(routeState);
      }
    };
    window.addEventListener('popstate', this._onPopState);
  },
  dispose() {
    window.removeEventListener('popstate', this._onPopState);
  },
};

export const stateMapping = {
  stateToRoute(uiState) {
    const index = Object.keys(uiState)[0];

    const filters = store.getters['fields/facets'].reduce((carry, facet) => {
      /* eslint-disable no-param-reassign */
      if (uiState?.[index]?.refinementList?.[facet.key] !== undefined) {
        carry[facet.key] = uiState[index].refinementList[facet.key].join('~');
      }
      /* eslint-enable no-param-reassign */
      return carry;
    }, {});

    return {
      [index]: {
        q: uiState[index].query,
        ...filters,
      },
    };
  },
  routeToState(routeState) {
    return routeState;
  },
};
