<template>
  <ais-instant-search
    :index-name="index"
    :search-client="searchClient"
    :routing="routing"
  >
    <router-view />
  </ais-instant-search>
</template>

<script>
import algoliasearch from 'algoliasearch/lite';
import { router, stateMapping } from '@/helpers/algoliaRouting';
import { mapState, mapGetters } from 'vuex';

export default {
  data() {
    return {
      searchClient: algoliasearch(
        this.$store.state.algolia_app_id,
        this.$store.state.algolia_search_api_key,
      ),
      routing: { router, stateMapping },
    };
  },
  computed: {
    ...mapState({
      directories: (state) => state.directories,
      name: (state) => state.name,
    }),
    ...mapGetters({
      currentDirectory: 'directory/current',
      index: 'directory/index',
    }),
  },
  mounted() {
    if (this.currentDirectory === undefined) {
      this.$router.push('/404');
    }
  },
};
</script>
